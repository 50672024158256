export function createUserMessage(text, isAutoGenerated=false) {
    return {
        text: text,
        sender: "user",
        isAutoGenerated: isAutoGenerated,
    }
}

export function createAIMessage(text, messageId, isError=false) {
    // fix for wrong response format 
    let finalText;
    if (typeof text === 'string') {
        finalText = text;
    } else if (text && typeof text.response_text === 'string') {
        finalText = text.response_text;
    } else {
        finalText = JSON.stringify(text);
    };
    
    return {
        text: finalText,
        sender: "ai",
        messageId: messageId,
        isError: isError,
        isReport: false
    }
}

export function createReportSuggestionMessage(text, messageId, reports = [], isError=false) {
    return {
        text: text,
        sender: "ai",
        messageId: messageId,
        isError: isError,
        isReport: true,
        reports: reports,
    }
}