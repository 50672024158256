import React from 'react';
import Typography from '@mui/material/Typography';

function MessageNote({text, ai=false}) {
    const bgcolor = ai ? 'info.main' : 'primary.main';
    const textColor = ai ? 'primary.main' : 'primary.contrastText';
    return (
        <Typography 
            variant="caption" 
            sx={{
                bgcolor: bgcolor, 
                borderRadius: '10px', 
                padding: '2px 6px', 
                fontSize: '0.7rem',
                border: `1px solid`,
                color: textColor
            }}
        >
            {text}
        </Typography>
    );
}

export default MessageNote;