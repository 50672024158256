import React, { useContext, useState } from 'react';
import { useTheme } from '@mui/material';
import { getSuggestions } from '../../utils/wordSuggestions';
import { ChatContext } from '../../contexts/ChatContext';
import { RichTextarea, createRegexRenderer } from "rich-textarea";

function MessageTextInput({ isLoading }) {
    const { input, setInput, setSuggestions, inputKeywords, handleSend, suggestions, handleSuggestionClick } = useContext(ChatContext);
    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);
    const regexArray = inputKeywords.map((term) => {
        try {
            const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const escapedTerm = escapeRegex(term);

            // Replace spaces in the term with non-breaking spaces for the regex
            const regexString = `\\u00A0${escapedTerm.replace(/\s+/g, '\\u00A0')}\\u00A0`;

            // Create the regex with non-breaking spaces
            const regex = new RegExp(regexString, "gi");
            return [
                regex, 
                { 
                    color: theme.palette.primary.main,
                    borderRadius: "4px",
                    outline: `solid 1px #de785f`,
                }
            ];
        } catch (error) {
            console.error(`Error creating regex for term: ${term}`, error);
            return null;  // Return null in case of error
        }
    }).filter(Boolean);  // Remove any null values from the array
    const renderer = regexArray.length > 0 ? createRegexRenderer(regexArray) : null;
    const handleChange = (e) => {
        const newInput = e.target.value;
        setInput(newInput);

        const lastWord = newInput.trim().split(/\s+/).pop();
        const lastChar = newInput.charAt(newInput.length - 1); 
        if (/[a-zA-Z]/.test(lastChar) && lastWord.length >= 2) {
            const newSuggestions = getSuggestions(lastWord);
            setSuggestions(newSuggestions);
        } else {
            setSuggestions([]);
        }  
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !isLoading) {
            if (e.shiftKey) {
                // Allow Shift + Enter to add a new line
                // Default action, do nothing extra here
            } else {
                e.preventDefault();
                handleSend(false);
            }
        } else if (e.key === 'Tab' && suggestions.length > 0) {
            e.preventDefault();
            handleSuggestionClick(suggestions[0]);
        }
    };

    return (
        <>
        <RichTextarea
            value={input}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={{
                width: '97%',
                padding: '0.5rem',
                borderRadius: '5px',
                border: `0.5px solid ${isFocused ? theme.palette.primary.main : '#ccc'}`,
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                outline: 'none',
                transition: 'border-color 0.2s',
                resize: 'none',
                overflow: 'auto',
            }}
            onFocus={() => setIsFocused(true)} // Set focus state
            onBlur={() => setIsFocused(false)} // Reset focus state
            rows={2}
        >
            {renderer}
        </RichTextarea>
        <style jsx="true">
                {`
                    /* Custom Scrollbar Styling */
                    ::-webkit-scrollbar {
                        width: 6px;
                        height: 6px
                    }

                    ::-webkit-scrollbar-thumb {
                        background-color: ${theme.palette.secondary.light};
                        border-radius: 10px;
                    }

                    ::-webkit-scrollbar-thumb:hover {
                        background-color: ${theme.palette.secondary.main};
                    }

                    ::-webkit-scrollbar-track {
                        background-color: #f1f1f1;
                        border-radius: 10px;
                    }

                    /* Firefox scrollbar */
                    scrollbar-width: thin;
                `}
            </style>
        </>
    )
}

export default MessageTextInput;