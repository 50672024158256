import React from 'react';
import { Box } from '@mui/material';
import MessagesArea from '../components/MessageDisplay/MessagesArea';
import MessageInputArea from '../components/MessageInput/MessageInputArea';


function ChatWindow({isEmbedded=false}) {
    if (isEmbedded) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100vh' 
                }}
            >
                <MessagesArea />
                <MessageInputArea
                    isEmbedded={isEmbedded}
                />
            </Box>
        )
    } else {
        return (
            <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '80%',
                mx: 'auto',
                position: 'relative',
                pb: '146px',
                pt: '64px'
            }}>
                <MessagesArea />
            </Box>
            <MessageInputArea 
                isEmbedded={isEmbedded}
            />
            </>
        )
    }
}

export default ChatWindow;