import { Box, Typography, Link } from "@mui/material"
import { body1ResponsiveSizes } from '../consts/CustomStyles';
import Header from "../components/Header";

function ChatWindowNoKey() {
    return (
        <>
        <Header />
        <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
            height: 'calc(100vh - 63px)',
            pt: '63px'
        }}
        >
            <Typography 
                variant="h4" 
                component="h2" 
                gutterBottom 
                sx={{ 
                    color: 'text.secondary', 
                }}
            >
                Hello, welcome to Conversational AI! 👋🤖
            </Typography>
            <Typography
                variant="body1"
                sx={{ 
                    mt: 2, 
                    color: 'text.secondary', 
                    textAlign: 'center',
                    fontSize: body1ResponsiveSizes 
                }}
            >
                To acces the chat please go to <Link href="https://portal-dev.ireckonu.com/#/analytics/conversational-ai" target="_blank" rel="noopener">Ireckonu portal</Link>
            </Typography>
        </Box>
        </>
    )
}

export default ChatWindowNoKey;