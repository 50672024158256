import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { body1ResponsiveSizes } from '../../consts/CustomStyles';


function ReportSuggestionContent({ text, reports }) {
    return (
        <>
            <Typography 
                variant="body1" 
                sx={{ 
                    marginBottom: '8px', 
                    fontSize: body1ResponsiveSizes,
                    marginTop: '8px' 
                }}
            >
                {text}
            </Typography>
            <Box 
                component="ul" 
                sx={{ 
                    paddingLeft: 2, 
                    margin: 0,
                    listStyleType: 'disc'
                }}
            >
                {reports.map((report, index) => (
                    <Typography 
                        key={index} 
                        component="li" 
                        variant="body2" 
                        sx={{ marginBottom: '4px' }}
                    >
                        <Link 
                            href={report.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            sx={{ textDecoration: 'none', color: 'primary.main', '&:hover': { textDecoration: 'underline' } }}
                        >
                            {report.title}
                        </Link>
                    </Typography>
                ))}
            </Box>
        </>
    )
}

export default ReportSuggestionContent;