import { useState, useEffect, useCallback, useContext } from 'react';
import { REQUEST_STATUS } from '../consts/RequestStatus';
import { KeyContext } from '../contexts/KeyContext';
import { createAIMessage, createUserMessage, createReportSuggestionMessage } from '../utils/createMessage';
import { replaceLastWord } from '../utils/wordSuggestions';
import { sendAPIRequestMain, sendAPIRequestQuestion, sendAPIRequestReportSuggestion } from '../services/apiService';
import { 
    GENERIC_ERROR_MESSAGE,
    DEFAULT_AI_MESSAGE,
    REPORTS_INTRO_MESSAGE,
    REPORT_INTRO_MESSAGE,
    REPORTS_NULL_MESSAGE,
    REPORTS_ERROR_MESSAGE 
} from '../consts/defaultMessages';
import { MESSAGE_TIMEOUT, MAX_AUTO_GENERATED_QUESTIONS } from '../consts/autoGenQustionsSettings';
import { preparePrompt, sendUserPrompt } from '../utils/requestAiHelpers';

function useRequestAI() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.IDLE);
    const [requestReportStatus, setRequestReportStatus] = useState(REQUEST_STATUS.IDLE);

    const [generatedQuestion, setGeneratedQuestion] = useState('');
    const [autoGeneratedQuestionCount, setAutoGeneratedQuestionCount] = useState(0);

    const [enthusiasticMode, setEnthusiasticMode] = useState(false);
    const [suggestReports, setSuggestReports] = useState(true);

    const [maxAutoGeneratedQuestions, setMaxAutoGeneratedQuestions] = useState(0);
    const { functionKey } = useContext(KeyContext);

    const [suggestions, setSuggestions] = useState([]);
    const [inputKeywords, setInputKeywords] = useState([]);

    const changeMaxAutoGeneratedQuestions = (isOn) => {
        if (isOn) {
            setMaxAutoGeneratedQuestions(MAX_AUTO_GENERATED_QUESTIONS);
        } else {
            setMaxAutoGeneratedQuestions(0);
        }
    }
    
    const getQuestion = useCallback(async () => {
        try {
            const response = await sendAPIRequestQuestion(functionKey);
            if (response.ok) {
                const responseText = await response.text();
                setGeneratedQuestion(responseText);
            } else {
                const errorText = await response.text();
                console.error(errorText)
            }
        } catch (error) {
            console.error(error.message)
        }
        
    }, [sendAPIRequestQuestion]);

    const getReportSuggestions = useCallback(async (responseId) => {
        try {
            setRequestReportStatus(REQUEST_STATUS.LOADING);
            const response = await sendAPIRequestReportSuggestion(responseId, functionKey);
            let reportMessage;
            if (response.ok) {
                const responseText = await response.text();
                if (responseText) {
                    const responseArray = JSON.parse(responseText);
                    if (responseArray.status && responseArray.status === "non-successful") {
                        reportMessage = createReportSuggestionMessage(REPORTS_ERROR_MESSAGE, null)
                    } else {
                        const reports = responseArray.reports;
                        if (reports.length > 1) {
                            reportMessage = createReportSuggestionMessage(REPORTS_INTRO_MESSAGE, responseArray.ID, reports)
                        } else if (reports.length > 0) {
                            reportMessage = createReportSuggestionMessage(REPORT_INTRO_MESSAGE, responseArray.ID, reports)
                        } else {
                            reportMessage = createReportSuggestionMessage(REPORTS_NULL_MESSAGE, null)
                        }
                    }
                } else {
                    reportMessage = createReportSuggestionMessage(REPORTS_NULL_MESSAGE, null)
                }
                setRequestReportStatus(REQUEST_STATUS.SUCCESS);
            } else {
                const errorText = await response.text();
                console.error(errorText)
                reportMessage = createReportSuggestionMessage(REPORTS_ERROR_MESSAGE, null)
                setRequestReportStatus(REQUEST_STATUS.FAILURE);
            }
            setMessages(messages => [...messages, reportMessage]);
        } catch (error) {
            console.error(error.message);
            setRequestReportStatus(REQUEST_STATUS.FAILURE);
            const reportMessage = createReportSuggestionMessage(REPORTS_ERROR_MESSAGE, null)
            setMessages(messages => [...messages, reportMessage]);
        }
    }, [])

    useEffect(() => {
        if (generatedQuestion.trim()) {
            handleSend(true);
        }
    }, [generatedQuestion])

    useEffect(() => {
        const handleTimeout = () => {
            if (autoGeneratedQuestionCount < maxAutoGeneratedQuestions) {
                getQuestion();
            }
        };
        const timeoutId = setTimeout(handleTimeout, MESSAGE_TIMEOUT);
        return () => clearTimeout(timeoutId);
    }, [messages, input]);

    const handleSuggestionClick = (suggestion) => {
        const updatedText  = replaceLastWord(suggestion, input)
        setInput(updatedText);
        setInputKeywords(inputKeywords => [...inputKeywords, suggestion]);
        setSuggestions([]);
    };

    const handleSend = useCallback(async (autoGenerated=false) => {
        if (requestStatus === REQUEST_STATUS.LOADING) {
            console.log('still loading')
            return
        };
        const prompt = preparePrompt(autoGenerated, input, generatedQuestion);
        if (!prompt) return;
        if (autoGenerated) {
            setAutoGeneratedQuestionCount(count => count + 1);
            setGeneratedQuestion('')
        } else {
            setAutoGeneratedQuestionCount(0);
            setInput('');
            setInputKeywords([]);
            setSuggestions([]);
        }
        setMessages(messages => [...messages, createUserMessage(prompt, autoGenerated)]);
        setRequestStatus(REQUEST_STATUS.LOADING);
        // handle prompts without alphanumeric characters
        if (!/[a-zA-Z0-9]/.test(prompt)) {
            const aiMessage = createAIMessage(DEFAULT_AI_MESSAGE, null)
            setMessages(messages => [...messages, aiMessage]);
            setRequestStatus(REQUEST_STATUS.SUCCESS);
            return;
        }
        // handle valid prompts
        try {
            const responseArray = await sendUserPrompt(prompt, autoGenerated, functionKey, enthusiasticMode);
            const aiResponse = responseArray.response_text;
            const responseId = responseArray.response_id;
            const responseStatus = responseArray.response_successful;
            // get report suggestions async
            if (responseStatus && suggestReports) {
                getReportSuggestions(responseId);
            }
            const aiMessage = createAIMessage(aiResponse, responseId)
            setRequestStatus(REQUEST_STATUS.SUCCESS);
            setMessages(messages => [...messages, aiMessage]);
            
        } catch (error) {
            const errorMessage = createAIMessage(GENERIC_ERROR_MESSAGE, null, true)
            console.error(error.message);
            setMessages(messages => [...messages, errorMessage]);
            setRequestStatus(REQUEST_STATUS.FAILURE);
        }
        
    }, [generatedQuestion, input, requestStatus, sendAPIRequestMain]);

    const clearChat = useCallback(() => {
        setMessages([]);
    }, []);

    return {
        messages,
        input, setInput,
        handleSend,
        requestStatus,
        clearChat,
        getQuestion,
        enthusiasticMode, setEnthusiasticMode,
        changeMaxAutoGeneratedQuestions,
        suggestions, setSuggestions,
        inputKeywords, setInputKeywords,
        handleSuggestionClick,
        suggestReports, setSuggestReports,
        requestReportStatus
    };
}

export default useRequestAI;