import { createContext } from "react";
import useRequestAI from "../hooks/useRequestAI";

const ChatContext = createContext('');

function ChatContextProvider({ children }) {
    const {
        messages,
        input,
        setInput,
        handleSend,
        requestStatus,
        clearChat,
        getQuestion,
        enthusiasticMode,
        setEnthusiasticMode,
        changeMaxAutoGeneratedQuestions,
        suggestions, setSuggestions,
        inputKeywords, setInputKeywords,
        handleSuggestionClick,
        suggestReports, setSuggestReports,
        requestReportStatus
    } = useRequestAI();
    return (
        <ChatContext.Provider
            value={{
                messages,
                input,
                setInput,
                handleSend,
                requestStatus,
                clearChat,
                getQuestion,
                enthusiasticMode,
                setEnthusiasticMode,
                changeMaxAutoGeneratedQuestions,
                suggestions, setSuggestions,
                inputKeywords, setInputKeywords,
                handleSuggestionClick,
                suggestReports, setSuggestReports,
                requestReportStatus
            }}
        >
            {children}
        </ChatContext.Provider>
    )
}

export { ChatContext, ChatContextProvider };