import React, { useRef, useEffect, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { REQUEST_STATUS } from '../../consts/RequestStatus';
import WelcomeCard from '../WelcomeCard/WelcomeCard';
import MessageCard from './MessageCard';
import LoadingMessage from './LoadingMessage';
import { ChatContext } from '../../contexts/ChatContext';

function MessagesArea() {
    const { messages, requestStatus, setInput, handleSend, requestReportStatus} = useContext(ChatContext);
    const messagesEndRef = useRef(null);
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);
    return (
      <Paper sx={{
        p: 2,
        flex: 1,
        overflowY: 'auto'
      }}>
        {messages.length === 0 ? (
            <WelcomeCard setInput={setInput} handleSend={handleSend}/>
        ): (
            <List>
                {messages.map((message, index) => (
                    <ListItem 
                        key={index} 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'flex-start' }}
                    >
                        <MessageCard message={message}/>
                    </ListItem>
                ))}
                {requestStatus === REQUEST_STATUS.LOADING && (
                    <LoadingMessage text={"Generating response..."}/>
                )}
                {requestReportStatus === REQUEST_STATUS.LOADING && (
                    <LoadingMessage text={"Searching for relevant reports..."}/>
                )}
                {/* Dummy div that acts as an auto-scroll target */}
                <div ref={messagesEndRef} />
            </List>
        )}

      </Paper>
    );
}

export default MessagesArea;