import React, { useState } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import { USER_FEEDBACK } from '../../consts/UserFeedback';
import useLogFeedback from '../../hooks/useLogFeedback';
import FeedbackDialog from './FeedbackDialog';

function FeedbackButtons({showFeedback, messageId}) {
    const { logUserFeedback } = useLogFeedback();
    const [userFeedback, setUserFeedback] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleThumbUp = () => {
        if (userFeedback === USER_FEEDBACK.POSITIVE) {
            return;
        }
        setUserFeedback(USER_FEEDBACK.POSITIVE);
        logUserFeedback(messageId, USER_FEEDBACK.POSITIVE);
    };
    const handleThumbDown = () => {
        setUserFeedback(USER_FEEDBACK.NEGATIVE);
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        logUserFeedback(messageId, USER_FEEDBACK.NEGATIVE);
    };
    const handleDialogSubmit = (comment) => {
        if (comment.trim()) {
            logUserFeedback(messageId, `${USER_FEEDBACK.NEGATIVE};${comment.trim()}`);
        } else {
            logUserFeedback(messageId, USER_FEEDBACK.NEGATIVE);
        }
        setDialogOpen(false);
    };
    const handleDialogCancel = () => {
        setDialogOpen(false);
        setUserFeedback(null);
    }
    if (showFeedback) {
        return (
            <CardActions>
                <IconButton onClick={handleThumbUp} color={userFeedback === USER_FEEDBACK.POSITIVE ? "primary" : "secondary"}>
                    <ThumbUpIcon />
                </IconButton>
                <IconButton 
                    onClick={handleThumbDown} 
                    color={userFeedback === USER_FEEDBACK.NEGATIVE ? "primary" : "secondary"}
                    disableFocusRipple
                >
                    <ThumbDownIcon />
                </IconButton>
                <FeedbackDialog open={dialogOpen} onClose={handleDialogClose} onSubmit={handleDialogSubmit} onCancel={handleDialogCancel}/>
            </CardActions>
        );
    } else {
        return null;
    }
}

export default FeedbackButtons;