import React, { useContext, useState } from 'react';
import { GlobalStyles, Box, Paper }  from '@mui/material';
import ClearChatHistoryDialog from './ClearChatHistoryDialog';
import InputSettings from './InputSettings';
import TextInputArea from './TextInputArea';
import Suggestions from './Suggestions';
import { ChatContext } from '../../contexts/ChatContext';

function MessageInputArea({ isEmbedded }) {
    const { clearChat } = useContext(ChatContext);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleClearChat = () => {
        clearChat();
        handleDialogClose();
    };

    const paperStyleEmbedded = { 
        display: 'flex', 
        alignItems: 'center', 
        padding: 2, 
    }

    const paperStyle = {
        p: 2,
        position: 'fixed',
        bottom: 0,
        left: '10%',
        right: '10%',
        zIndex: 10,
    }
    return (
        <Paper
            elevation={3}
            sx={isEmbedded ? paperStyleEmbedded : paperStyle}
        >
            <GlobalStyles styles={{
                'body': { paddingRight: '0 !important', overflow: 'unset !important' }
            }} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                background: '#fff',
                alignItems: 'left'
            }}>
                <InputSettings />
                <Suggestions />
                <TextInputArea handleDialogOpen={handleDialogOpen} />
            </Box>
            <ClearChatHistoryDialog
                dialogOpen={dialogOpen}
                handleDialogClose={handleDialogClose}
                handleClearChat={handleClearChat}
            />
        </Paper>
    );
}

export default MessageInputArea;