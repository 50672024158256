import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid, Container } from '@mui/material';
import { body1ResponsiveSizes } from '../../consts/CustomStyles';
import ExampleCard from './ExampleCard';

function WelcomeCard({setInput, handleSend}) {
    const [inputSet, setInputSet] = useState(false);

    const handleCardClick = (example) => {
        setInput(example);
        setInputSet(true);
    }

    useEffect(() => {
        if (inputSet) {
            handleSend(false);
            setInputSet(false);
        }
    }, [inputSet, handleSend]);

    const examples = [
        "Show me a number of reservations from last month.",
        "How many reservations were made by guests who subscribed to the newsletter",
        "What is the total room revenue?",
        "How many guests or profiles are there?",
        
    ];

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '5vh',
        }}>
            <Typography 
                variant="h4" 
                component="h2" 
                gutterBottom 
                sx={{ 
                    color: 'text.secondary', 
                }}
            >
                Hello, welcome to Conversational AI! 👋🤖
            </Typography>
            <Typography
                variant="body1"
                sx={{ 
                    mt: 2, 
                    color: 'text.secondary', 
                    textAlign: 'center',
                    fontSize: body1ResponsiveSizes 
                }}
            >
                Feel free to ask any questions about your data.
            </Typography>
            <Typography
                variant="body1"
                sx={{ 
                    mt: 2, 
                    textAlign: 'center', 
                    color: 'text.secondary', 
                    fontSize: body1ResponsiveSizes 
                }}
            >
                Example questions you might consider:
            </Typography>
            <Container
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <Grid container spacing={2} justifyContent="center" sx={{mt: 1}}>
                    {examples.map((example, index) => (
                        <ExampleCard key={index} example={example} index={index} handleCardClick={handleCardClick}/>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

export default WelcomeCard;