import React, { useContext } from 'react';
import { Switch, FormControlLabel }  from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/material';
import { ChatContext } from '../../contexts/ChatContext';

function InputSettings() {
    const {
        enthusiasticMode, setEnthusiasticMode, 
        changeMaxAutoGeneratedQuestions, 
        suggestReports, setSuggestReports
    } = useContext(ChatContext);
    const [questionToggle, setQuestionToggle] = useState(false);

    const handleModeToggleChange = (event) => {
        setEnthusiasticMode(event.target.checked);
    };

    const handleQuestionToggleChange = (event) => {
        setQuestionToggle(event.target.checked);
        changeMaxAutoGeneratedQuestions(event.target.checked);
    }

    const handleReportToggleChange = (event) => {
        setSuggestReports(event.target.checked);
    }
    return (
        <Box
            display="flex"
            alignItems="flex-start" 
            width="100%"
            flexDirection='row'
        >
        <FormControlLabel
            sx={{
                paddingLeft: '10px'
            }}
            control={
                <Switch
                    checked={enthusiasticMode}
                    onChange={handleModeToggleChange}
                    color="primary"
                />
            }
            label="Enthusiastic Mode"
        />
        <FormControlLabel
            sx={{
                paddingLeft: '10px'
            }}
            control={
                <Switch
                    checked={questionToggle}
                    onChange={handleQuestionToggleChange}
                    color="primary"
                />
            }
            label="Auto Generate Questions"
        />
        <FormControlLabel
            sx={{
                paddingLeft: '10px'
            }}
            control={
                <Switch
                    checked={suggestReports}
                    onChange={handleReportToggleChange}
                    color="primary"
                />
            }
            label="Suggest Reports"
        />
        </Box>
    )
}

export default InputSettings;