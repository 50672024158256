import React from 'react';
import UserIcon from '@mui/icons-material/AccountCircle';
import AIIcon from '@mui/icons-material/Computer';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/material';
import FeedbackButtons from '../UserFeedback/FeedbackButtons';
import { body1ResponsiveSizes } from '../../consts/CustomStyles';
import MessageNote from './MessageNote';
import ReportSuggestionContent from './ReportSuggestionContent';

function MessageCard({ message }) {
    const isError = message.isError;
    const isAutoGenerated = message.sender === 'user' && message.isAutoGenerated;
    const avatarBgColor = message.sender === 'ai' ? 'info.main' : 'primary.main';
    const cardBgColor = isError ? '#ffcdd2' : (message.sender === 'ai' ? 'info.main' : 'primary.main');
    const textColor = isError ? '#c62828' : (message.sender === 'ai' ? 'info.contrastText' : 'secondary.contrastText');
    const showFeedback = (message.sender === 'ai') & !isError;
    let isReport = false;
    if (message.sender === 'ai') {
        if (message.isReport) {
            isReport = true;
        }
    }

    return (
        <Box 
            display="flex"
            alignItems="flex-start" 
            width="100%"
            flexDirection={message.sender === 'user' ? 'row-reverse' : 'row'}
        >
            <Avatar sx={{ bgcolor: avatarBgColor, marginRight: 2 }}>
                {message.sender === 'user' ? <UserIcon sx={{ color: 'main.contrastText' }}/> : <AIIcon sx={{ color: 'info.contrastText' }} />}
            </Avatar>
            <Box sx={{
                maxWidth: { xs: '100%', sm: '80%', md: '60%', lg: '60%', xl: '60%' },
            }}>
            <Card sx={{
                bgcolor: cardBgColor,
                color: textColor,
                borderRadius: '20px',
                margin: '8px',
                padding: '0px 10px',
                wordBreak: 'break-word',
            }}>
                <CardContent>
                    {message.isAutoGenerated && 
                        <MessageNote text={"Auto-generated"}/>
                    }
                    {isReport ? (
                        <>
                        <MessageNote text={"Report Suggestions"} ai={true}/>
                        <ReportSuggestionContent text={message.text} reports={message.reports}/>
                        </>
                    ): (
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                marginTop: isAutoGenerated ? '8px' : '0',
                                fontSize: body1ResponsiveSizes,
                            }}
                        > 
                            {message.text}
                        </Typography>
                    )}
                    
                </CardContent>
            </Card>
            
            </Box>
            {!isReport &&
                <FeedbackButtons showFeedback={showFeedback} messageId={message.messageId}/>
            }
            
        </Box>
    );
}

export default MessageCard;