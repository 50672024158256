import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Grid } from '@mui/material';
import { body1ResponsiveSizes } from '../../consts/CustomStyles';

function ExampleCard({example, index, handleCardClick}) {
    return (
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index} sx={{display: 'flex', justifyContent: 'center'}}>
            <Card 
                sx={{ 
                    height: '100%', 
                    width: '100%',
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    textAlign: 'center', 
                    maxWidth: 200,
                    minWidth: 150,
                    margin: '10px',
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'primary.main',
                    boxShadow: 3,
                    bgcolor: 'info.main',
                    '&:hover': {
                        transform: 'scale(1.03)',
                        transition: 'transform 0.05s ease-in-out',
                    }
                }}
            >
                <CardActionArea
                    onClick={() => handleCardClick(example)}
                    sx={{
                        height: '100%', 
                        width: '100%'
                    }}
                >
                    <CardContent >
                        <Typography 
                            variant="body1" 
                            sx={{
                                color: 'info.contrastText',
                                fontSize: body1ResponsiveSizes,
                            }}
                        >
                            {example}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default ExampleCard;