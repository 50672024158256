import { normalizeMessage } from "./wordSuggestions";
import { sendAPIRequestMain } from "../services/apiService";

export const preparePrompt = (autoGenerated, input, generatedQuestion) => {
    if (autoGenerated) {
        return generatedQuestion.trim();
    }
    return normalizeMessage(input);
}

export const sendUserPrompt = async (prompt, autoGenerated, functionKey, enthusiasticMode) => {
    const requestBody = {
        userprompt: prompt,
        isAutoGenQuestion: autoGenerated,
        roleInfoMode: enthusiasticMode ? 'funny' : 'basic'
    };
    const response = await sendAPIRequestMain(requestBody, functionKey);
    if (!response.ok) {
        throw new Error(await response.text());
    }
    return JSON.parse(await response.text());
}