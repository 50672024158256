import React, {useContext} from 'react';
import { Box, Button, Typography } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { ChatContext } from '../../contexts/ChatContext';

function Suggestions() {
    const {suggestions, handleSuggestionClick} = useContext(ChatContext);
    
    return (
        <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        padding: 1,
        maxWidth: '100%',
        overflowX: 'auto',
        minHeight: suggestions.length > 0 
            ? 'auto' 
            : '2rem',
        }}>
        {suggestions.map((suggestion, index) => (
            <Button
            key={index}
            variant="outlined"
            sx={{
                padding: '5px 15px',
                borderRadius: '20px',
                textTransform: 'none',
                minWidth: 'fit-content',
            }}
            onClick={() => handleSuggestionClick(suggestion)}
            >
            <Typography variant="body2">{suggestion}</Typography>
            </Button>
        ))}
        {!suggestions.length && (
            <Box 
                sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    height: '100%', 
                    color: 'gray',
                    padding: '5px 5px'
                }}
            >
                <LightbulbOutlinedIcon 
                    sx={{ 
                        marginRight: '8px', 
                        fontSize: '20px' 
                    }} 
                /> 
                <Typography variant="body2">Suggestions will appear here...</Typography>
            </Box>
        )}
        </Box>
    );
}

export default Suggestions;