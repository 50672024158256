export const terms = [
    "Average Daily Rate", "ADR", "Avg Daily Rate", "Average Rate per Day", 
    "Revenue Per Available Room", "RevPAR", "Revenue per Avail Room", 
    "Revenue/Available Room", "Occupancy Rate", "OCC", "Occupancy", 
    "Occupancy Percentage"
];

export const termMapping = {
    "ADR": "Average Daily Rate",
    "Average Daily Rate": "Average Daily Rate",
    "Avg Daily Rate": "Average Daily Rate",
    "Average Rate per Day": "Average Daily Rate",
    "Revenue Per Available Room": "RevPAR",
    "RevPAR": "RevPAR",
    "Revenue per Avail Room": "RevPAR",
    "Revenue/Available Room": "RevPAR",
    "Occupancy Rate": "Occupancy Rate",
    "OCC": "Occupancy Rate",
    "Occupancy": "Occupancy Rate",
    "Occupancy Percentage": "Occupancy Rate"
};