import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle }  from '@mui/material';

function FeedbackDialog({ open, onClose, onSubmit, onCancel }) {
    const [comment, setComment] = useState('');

    const handleSubmit = () => {
        onSubmit(comment);
        setComment('');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                // Allow Shift + Enter to add a new line
                // Default action, do nothing extra here
            } else {
                e.preventDefault();
                onSubmit(comment);
                setComment('');
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Feedback</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Could you please tell us what was wrong with the answer so we can improve?
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="comment"
                    label="Your Comment"
                    type="text"
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={4}
                    value={comment}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setComment(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={handleSubmit} 
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FeedbackDialog;