import Fuse from 'fuse.js';
import { terms, termMapping } from "../consts/terms";


const prefixMatch = (word) => {
    return terms
      .filter(keyword => keyword.toLowerCase().startsWith(word))
      .map(keyword => termMapping[keyword] || keyword);
}

const flexibleMatch = (word) => {
    return terms
        .filter(keyword => keyword.toLowerCase().includes(word))
        .map(keyword => termMapping[keyword] || keyword);
}

const getSuggestionsFuse = (input, thr=0.4) => {
    const terms = Object.keys(termMapping).map(term => ({
        term: termMapping[term],
        original: term
    }));
    const options = {
        includeScore: true,
        threshold: thr,
        keys: ['original']
    };
    const fuse = new Fuse(terms, options);
    const results = fuse.search(input);
    // result.score of 0 indicates a perfect match, while a score of 1 indicates a complete mismatch (https://www.fusejs.io/api/options.html)
    const results_score = results
        .map(result => ({
            term: result.item.term,
            similarity: 1 - result.score
        }))
        .sort((a, b) => b.similarity - a.similarity);
    const suggestions = results_score.map(x => x.term)
    return suggestions;
}

export const getSuggestions = (userInput) => {
    const userInputLower = userInput.trim().toLowerCase();
    let suggestions = flexibleMatch(userInputLower);
    if (suggestions.length === 0) {
        suggestions = getSuggestionsFuse(userInputLower);
    }
    return Array.from(new Set(suggestions)).slice(0, 3);
}

export const replaceLastWord = (replacement, text) => {
    const lastSpaceIndex = text.lastIndexOf(' ');
    const nonBreakingSegment = `\u00A0${replacement.replace(/\s+/g, '\u00A0')}\u00A0`;
    // const updatedText = 
    //     text.slice(0, lastSpaceIndex + 1) + ' ' + replacement + ' ' + ' ';
    const updatedText = 
        text.slice(0, lastSpaceIndex + 1) + nonBreakingSegment + ' ';
    return updatedText;
}

export const normalizeMessage = (text) => {
    // Replace non-breaking spaces (\u00A0) with a regular space
    let updatedText = text.replace(/\u00A0/g, ' ');

    // Replace multiple spaces (including double spaces) with a single space
    updatedText = updatedText.replace(/\s+/g, ' ');

    // Trim leading and trailing spaces, if any
    updatedText = updatedText.trim();

    return updatedText;
}