import React from 'react';
import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { KeyProvider } from './contexts/KeyContext';
import { ChatContextProvider } from './contexts/ChatContext';
import ChatWindow from './pages/ChatWindow';
import ChatWindowLoading from './pages/ChatWindowLoading';
import ChatWindowNoKey from './pages/ChatWindowNoKey';



let ireckonuTheme = createTheme({
    palette: {
        primary: {
            main: '#D65737',
            contrastText: '#fff'
        },
        secondary: {
            main:'#B3A293',
            contrastText: '#fff'
        },
        info: {
            main: '#F4F0E7',
            contrastText: '#45382D'
        }
    },
    typography: {
        fontFamily: 'Inter'
    },
});

ireckonuTheme = responsiveFontSizes(ireckonuTheme);

function App() {
    const [isEmbedded, setIsEmbedded] = useState(true);
    const [functionKey, setFunctionKey] = useState('');

    useEffect(() => {
        setIsEmbedded(window.self !== window.top);
        const params = new URLSearchParams(window.location.search);
        const functionKeyValue = params.get('functionKey');
        if (functionKeyValue) {
          setFunctionKey(functionKeyValue);
        }
    }, []);

    if (isEmbedded && functionKey) {
        return (
            <KeyProvider
                    functionKey={functionKey}
                >
                <ThemeProvider theme={ireckonuTheme}>
                    <ChatContextProvider>
                        <ChatWindow isEmbedded={true}/>
                    </ChatContextProvider>
                </ThemeProvider>
            </KeyProvider>
            
        )
    } else if (isEmbedded && !functionKey) {
        return (
            <ThemeProvider theme={ireckonuTheme}>
                <ChatWindowLoading />
            </ThemeProvider>
        )
    } else {
        return (
            <ThemeProvider theme={ireckonuTheme}>
                <ChatWindowNoKey/>
            </ThemeProvider>
        )
    }
}

export default App;