import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

function LoadingMessage({text}) {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2
        }}>
            <CircularProgress size={24} />
            <Typography variant="body1" sx={{ ml: 2 }}>
                {text}
            </Typography>
        </Box>
    )
}

export default LoadingMessage;