import { useState, useCallback } from 'react';
import { useContext } from 'react';
import { KeyContext } from '../contexts/KeyContext';
import { REQUEST_STATUS } from '../consts/RequestStatus';
import { sendAPIFeedback } from '../services/apiService';

function useLogFeedback() {
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.IDLE);
    const { functionKey } = useContext(KeyContext);

    const logUserFeedback = useCallback(async (messageId, feedback) => {
        setRequestStatus(REQUEST_STATUS.LOADING);
        const requestBody = {
            response_id: messageId,
            user_feedback: feedback
        };
        try {
            const response = await sendAPIFeedback(requestBody, functionKey);
            if (response.ok) {
                const responseText = await response.text();
                console.log(`Id: ${messageId}, Feedback: "${feedback}", Response: ${responseText}`)
                // console.log("Id", messageId, " ", feedback, responseText);
                setRequestStatus(REQUEST_STATUS.SUCCESS);
            } else {
                const responseText = await response.text();
                console.log("Id", messageId, "Error", responseText);
                setRequestStatus(REQUEST_STATUS.FAILURE);
            }
        } catch (error) {
            console.log("Id", messageId, "Error", error.message);
            setRequestStatus(REQUEST_STATUS.FAILURE);
        }
    })

    return {
        logUserFeedback,
    }

}

export default useLogFeedback;