import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';


function ChatWindowLoading() {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
            height: '100vh'
        }}>
            <CircularProgress size={24} />
            <Typography variant="body1" sx={{ ml: 2 }}>
                Loading...
            </Typography>
        </Box>
    )
}

export default ChatWindowLoading;