import React, { useContext }  from 'react';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import MessageTextInput from './MessageTextInput';
import { ChatContext } from '../../contexts/ChatContext';
import { REQUEST_STATUS } from '../../consts/RequestStatus';

function TextInputArea({handleDialogOpen}) {
    const { requestStatus, messages, handleSend, getQuestion, requestReportStatus } = useContext(ChatContext);
    const isLoading = requestStatus === REQUEST_STATUS.LOADING || requestReportStatus === REQUEST_STATUS.LOADING;
    const isChatEmpty = messages.length === 0;
    const handleSendClick = () => {
        if (!isLoading) {
            handleSend(false);
        }
    }
    return (
        <Box sx={{
            display: 'flex',
            padding: '10px',
            background: '#fff',
            alignItems: 'center'
        }}>
            <MessageTextInput
                isLoading={isLoading}
            />
            <Button
                variant="contained"
                color="secondary"
                onClick={handleSendClick}
                disabled={isLoading}
                sx={{
                    height: '56px',
                    marginLeft: '10px'
                }}
            >
                <b>Send</b>
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={getQuestion}
                disabled={isLoading}
                sx={{
                    height: '56px',
                    marginLeft: '10px'
                }}
            >
                <b>Random Question</b>
            </Button>
            <IconButton
                onClick={handleDialogOpen}
                disabled={isLoading || isChatEmpty}
                color="secondary"
                sx={{
                    height: '56px',
                    marginLeft: '10px'
                }}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
    )
}

export default TextInputArea;