import { reportSuggestionsResponseMock } from "../mocks/reportSuggestionsResponseMock";
import { aiResponseMock } from "../mocks/aiResponseMock";
import { autoGenQuestionResponseMock } from "../mocks/autoGenQuestionResponseMock";
import { logUserFeedbackResponseMock } from "../mocks/logUserFeedbackResponseMock";

export const sendAPIRequestMain = async (requestBody, functionKey) => {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
        await new Promise(resolve => setTimeout(resolve, 1500));
        return {
            ok: true, 
            text: async () => JSON.stringify(aiResponseMock),
        };
    }
    const apiRequest = process.env.REACT_APP_MAIN_ORCHESTRATOR_URL + functionKey;
    const response = await fetch(apiRequest, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    });
    return response;
};

export const sendAPIRequestQuestion = async (functionKey) => {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
        await new Promise(resolve => setTimeout(resolve, 500));
        return {
            ok: true,
            text: async () => autoGenQuestionResponseMock,
        };
    }
    const apiRequest = process.env.REACT_APP_AUTO_GEN_QUESTION_URL + functionKey;
    const response = await fetch(apiRequest, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};

export const sendAPIRequestReportSuggestion = async (responseId, functionKey) => {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
        await new Promise(resolve => setTimeout(resolve, 1500));
        return {
            ok: true,
            text: async () => JSON.stringify(reportSuggestionsResponseMock),
        };
    }
    const apiRequest = process.env.REACT_APP_SUGGEST_REPORT_URL + functionKey;
    const requestBody = {
        response_id: responseId
    }
    const response = await fetch(apiRequest, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    });
    return response;
};

export const sendAPIFeedback = async (requestBody, functionKey) => {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
        await new Promise(resolve => setTimeout(resolve, 500));
        return {
            ok: true,
            text: async () => logUserFeedbackResponseMock,
        };
    }
    const apiRequest = process.env.REACT_APP_FEEDBACK_URL + functionKey;
    const response = await fetch(apiRequest, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    });
    return response;
};