import React from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle }  from '@mui/material';

function ClearChatHistoryDialog({dialogOpen, handleDialogClose, handleClearChat}) {
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>Clear Chat History</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to clear chat history?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleDialogClose}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={handleClearChat}
                    autoFocus
                >
                    Clear Chat
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClearChatHistoryDialog;