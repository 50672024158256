import { createContext } from "react";

const KeyContext = createContext('');

function KeyProvider({ children, functionKey }) {
    return (
        <KeyContext.Provider value={{functionKey}}>
            {children}
        </KeyContext.Provider>
    );
}

export { KeyContext, KeyProvider };